<template>
  <v-container>
    <v-card>
      <v-card-title>Sample Payment</v-card-title>
      <v-card-text>
        <v-form ref="form1" v-model="valid" @submit.prevent="submitForm">
          <v-row>
            <v-col cols="12" sm="6" md="4" lg="3">
              <v-text-field
                v-model="amount"
                :rules="amountRules"
                label="Amount"
                required
              ></v-text-field>
            </v-col>
          </v-row>
          <v-btn
            :disabled="!valid"
            color="primary"
            @click="submitForm"
            :loading="SubmitFlag"
            >Submit</v-btn
          >
        </v-form>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import common from "@/view/Common.vue";
import companyConfig from "@/company_config.json";

export default {
  mixins: [common],
  data() {
    return {
      valid: false,
      amount: "",
      amountRules: [
        (v) => !!v || "Amount is required",
        (v) => (v && !isNaN(v)) || "Amount must be a number",
        (v) => (v && v > 0) || "Amount must be greater than zero",
      ],

      SubmitFlag: false,
    };
  },
  methods: {
    submitForm() {
      console.log("submitForm is called");

      var validate1 = this.$refs.form1.validate();
      var Amount = this.amount;
      console.log("validate1=" + validate1 + ", Amount=" + Amount);

      // Amount=0;

      if (validate1 && Amount > 0) {
        this.SubmitFlag = true;

        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        var add_url = server_url + "api/billdesk/sample/order-create";
        var upload = {
          UserInterface: 1,
          Amount: Amount,
        };
        console.log("upload=" + JSON.stringify(upload));
        console.log(
          "server_url=" +
            server_url +
            ", token=" +
            token +
            ", add_url=" +
            add_url
        );

        // this.$session.set("checkout", upload);
        // this.checkout();

        const thisIns = this;
        var output = "";
        var records = "";
        var flag = 0;

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this),
        })
          .then(function (response) {
            console.log("response=" + JSON.stringify(response));

            // thisIns.pageLoadingOff()
            thisIns.SubmitFlag = false;

            output = response.data.output;
            flag = response.data.flag;
            console.log("output=" + output + ", flag=" + flag);

            records = response.data.records;
            console.log({ records });

            if (flag == 1) {
              thisIns.billdeskPayment(records);
            } else {
              // thisIns.toast("error", output);
              thisIns.sweetAlert("info", output, false);
            }
          })
          .catch(function (error) {
            console.log("error=" + error);
            thisIns.SubmitFlag = false;
          });
      } else {
        var message = "";
        if (!validate1) {
          message += "Kindly fill the required fields. ";
        }
        if (Amount == 0) {
          message += "Minimum should not be zero. ";
        }
        this.sweetAlert("error", message, false);
        // this.toast("error", message);
      }
    },
    billdeskPayment(records) {
      console.log("billdeskPayment called");
      console.log({ records });

      var OrderConfig = records.order_config;
      var ThemeConfig = records.theme_config;

      const thisIns = this;

      var responseHandler = function (txn) {
        console.log({ txn });

        var response = txn.txnResponse;
        console.log({ response });

        var message = response.hasOwnProperty("message")
          ? response.message
          : "";
        console.log({ message });

        var transactionResponse = response.hasOwnProperty(
          "transaction_response"
        )
          ? response.transaction_response
          : "";
        console.log({ transactionResponse });

        if (transactionResponse != "") {
          thisIns.SubmitFlag = true;
          thisIns.sweetAlert("success", "Payment success.", true);
        } else {
          thisIns.sweetAlert("error", message, false);
        }
      };

      var config = {
        responseHandler: responseHandler,
        // merchantLogo: "data:image/png;base64:eqwewqesddhgjdxsc==",
        flowConfig: OrderConfig,
        flowType: "payments",
        themeConfig: ThemeConfig,
      };

      window.loadBillDeskSdk(config);
    },
  },
};
</script>

<style scoped>
.v-container {
  max-width: 600px;
  margin: 0 auto;
}
</style>